import React from 'react';
import { Flex, Text } from '@chakra-ui/core';

import { colors } from '@theme';
import { APP_CONTENT_DESKTOP_WIDTH } from '../../styles/sizes';
import ContentContainer from '../ContentContainer';
import animation from '../../lotties/circle-animation.json';
import { cloudfrontBaseUrl } from '../../lib/consts';
import TextWithImageRow from '../zeta/TextWithImageRow';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation,
};

const Unique = () => {
  const UniqueSection1 = `${cloudfrontBaseUrl}/homepage-24/home-uniqueSection1apy-2.09.jpg`;
  const UniqueSection2 = `${cloudfrontBaseUrl}/homepage-24/uniqueSection2.jpg`;
  const UniqueSection3 = `${cloudfrontBaseUrl}/homepage-24/home-uniqueSection3-new.jpg`;
  const UniqueSection4 = `${cloudfrontBaseUrl}/homepage-24/uniqueSection4.png`;
  const UniqueSectionMobile1 = `${cloudfrontBaseUrl}/homepage-24/home-uniqueSection1apy-2.09-mobile.png`;
  const UniqueSectionMobile2 = `${cloudfrontBaseUrl}/homepage-24/home-uniqueSection2-mobile-new.png`;
  const UniqueSectionMobile3 = `${cloudfrontBaseUrl}/homepage-24/home-uniqueSection3-new-mobile.png`;
  const UniqueSectionMobile4 = `${cloudfrontBaseUrl}/homepage-24/home-uniqueSection4-mobile.png`;

  return (
    <div style={{ backgroundColor: colors.white }}>
      <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
        <Flex paddingBottom={[10, 24]} paddingX={[0, 16, 16, 8]} direction="column">
          <Flex
            justify="space-between"
            align="center"
            direction="row"
            paddingTop={['24px', '24px', '24px', '168px']}
            paddingBottom="64px"
          >
            <Flex direction="column" alignItems="center">
              <Flex align="center" justify="center" width="100%" paddingBottom={'24px'}>
                <Text
                  textAlign="center"
                  color={colors.darkGray}
                  fontSize={['32px', '32px', '32px', '48px']}
                  fontWeight={'600'}
                  lineHeight={'1.2'}
                >
                  What's unique about Zeta
                </Text>
              </Flex>

              <TextWithImageRow
                tag="UNLIMITED ENVELOPES"
                title={`Save together for your\nbills & goals`}
                body="With your Zeta account, you can create envelopes for every bill and goal. This makes it easy to organize your money and set funds aside, keeping your family perfectly in sync with your finances."
                image={UniqueSection1}
                mobileImage={UniqueSectionMobile1}
                mobileBackground={'#FFEFEF'}
                fullWidthMobileBackground={true}
                imageOnRight={true}
              />

              <TextWithImageRow
                tag="AUTOMATE YOUR MONEY"
                title={`Get your time back`}
                body="Let Zeta take over your money chores. We'll help you set money aside,
                  automate payments, and even allocate your paycheck based on upcoming
                  expenses. Our mission is to take over the grunt work so you can laugh
                  more and worry less."
                image={UniqueSection2}
                mobileImage={UniqueSectionMobile2}
                mobileBackground={'#FDFBF0'}
                imageOnRight={false}
              />

              <TextWithImageRow
                tag="FREE CONCIERGE"
                title={`Hands-on support when\nyou need it`}
                body="Every account gets a Zeta Concierge, a team of humans and AI designed to
                  help you get more out of your account. Your Concierge is there to cheer
                  you on, help with specific tasks and support you along the way."
                image={UniqueSection3}
                mobileImage={UniqueSectionMobile3}
                mobileBackground={'#F7FFF1'}
                imageOnRight={true}
              />

              <TextWithImageRow
                tag="instant PAYMENTS"
                title={`Move money effortlessly`}
                body="Instantly fund your account, pay someone, move money across your Zeta
                  accounts or send it to a different account. Your money should go when
                  and where you need it."
                image={UniqueSection4}
                mobileImage={UniqueSectionMobile4}
                mobileBackground={'#F6FDFF'}
                imageOnRight={false}
              />
            </Flex>
          </Flex>
        </Flex>
      </ContentContainer>
    </div>
  );
};

export default Unique;
